import Link from 'next/link'

export const LinkText = ({ href, target, children, className, ...props }) => {
  return (
    <Link href={href || ''}>
      <a target={target} className={className} {...props}>
        {children}
      </a>
    </Link>
  )
}
