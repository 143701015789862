import styles from './style.module.scss'
import { Row, Col, Container, Spinner } from 'react-bootstrap'
import { Canvas } from '@react-three/fiber'
import React, { Suspense } from 'react'
import { OrbitControls, Html, useProgress } from '@react-three/drei'
import { Model } from './components/AvatarCO3D'
import SearchAll from '../SearchAll'
import { useTranslation } from '../../services/translation'

function BannerHome() {
  function Loader() {
    const { progress } = useProgress()
    return (
      <Html center>
        <Spinner
          as='span'
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
        />{' '}
        {Math.trunc(progress)}%
      </Html>
    )
  }
  const { t } = useTranslation()

  return (
    <>
      <div className={`b-inter ${styles.bHome}`}></div>
      <div className={`mb-4 ${styles.banner}`}>
        <Container>
          <Row>
            <Col
              className='mt-n2'
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 4, order: 2 }}
              lg={{ span: 4, order: 2 }}
            >
              <Canvas
                camera={{ position: [0, 0, 6.5], fov: 13 }}
                style={{
                  height: '320px',
                }}
              >
                <ambientLight intensity={1.25} />
                <ambientLight intensity={0.1} />
                <directionalLight intensity={0.4} />
                <Suspense fallback={<Loader />}>
                  <Model position={[0.2, -0.7, 0]} />
                </Suspense>
                <OrbitControls
                  enablePan={false}
                  enableZoom={false}
                  autoRotate
                />
              </Canvas>
            </Col>

            <Col
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 8, order: 1 }}
              lg={{ span: 8, order: 1 }}
            >
              <h1 className={`fs-2 mt-sm-5 ${styles.homeTit}`}>
                {t('home.bannerTit')}
              </h1>
              <h2 className={`fs-5 py-2 ${styles.homeSubTit}`}>
                {t('home.bannerSubTit')}
              </h2>
              <SearchAll className='pt-2 pb-4' />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default BannerHome
