import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Model(props) {
  useEffect(() => {
    actions['Armature|mixamo.com|Layer0'].play()
  })

  const group = useRef()
  const { nodes, materials, animations } = useGLTF(
    'https://storage.googleapis.com/static-co3d/assets/img/co3dMascot.glb'
  )
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <group name='Armature' rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh
            name='Ch46'
            geometry={nodes.Ch46.geometry}
            material={materials.Ch46_body}
            skeleton={nodes.Ch46.skeleton}
          />
        </group>
      </group>
    </group>
  )
}
