import React from 'react'
import { Card } from 'react-bootstrap'
import ModalParticipa from '../ModalParticipa'
import { useTranslation } from '../../services/translation'
import ModalProposal from './components/ModalProposal'
import Price from '../Price'
import Link from 'next/link'
import Router from 'next/router'
import Image from '../Image'
import imageLoader from '../../utils/imageLoader'
import styles from './style.module.scss'

function CardProject({ model, onUpdateModel }) {
  const { t } = useTranslation()

  const redirectToDetail = () => {
    Router.push(`/m/${model.slug}?om=1`)
  }
  return (
    <Card className='cardBase'>
      <Card.Header className='cardHeader'>
        <Image
          className={model.sensitiveContent ? 'img-blur' : ''}
          alt={`${model.name}, ${model.description}`}
          src={model.featuredPhoto.photo}
          layout='fill'
          objectFit='cover'
          quality={50}
          placeholder='blur'
          blurDataURL={imageLoader(300, 300)}
        />

        {model.sensitiveContent && (
          <div className='sensitive-content'>
            <p>{t('itemcard.sensitivecontent')}</p>
          </div>
        )}

        {model.partner && (
          <>
            <div className='co3dpartnerbg' />
            <div className='co3dpartnertxt'>
              {t('modal.parceiro')}
              <br />
              <img src='./favicons/favicon-32x32.png' />
            </div>
          </>
        )}

        <Link href={`/m/${model.slug}`} passHref>
          <Card.Link className='img-card'>
            <p>{model.name}</p>
            {model.status !== 'REQUESTED' && (
              <p>
                {t('itemcard.design')} {model.modeler.name}
              </p>
            )}
            <div className='text-center'>
              <i className='fa fa-plus-circle fs-xlarge' aria-hidden='true'></i>
            </div>
          </Card.Link>
        </Link>
        {
          {
            RAISING_FUNDS: (
              <div className={styles.labelFunding}>
                <i /> {t('itemcard.tipovaca')}
              </div>
            ),
            MODELING: (
              <div className={styles.labelModeling}>
                {t('itemcard.tipomodel')}
              </div>
            ),
          }[model.status]
        }
      </Card.Header>
      <Card.Body>
        <Card.Text className='mb-1'>
          {model.status === 'REQUESTED' && model.name}
          {model.status !== 'REQUESTED' && (
            <span className='preco'>
              {model.quotaPriceWithFee === 0 ? (
                t('itemcard.free')
              ) : (
                <Price value={model.quotaPriceWithFee} />
              )}
            </span>
          )}
        </Card.Text>
        {model.status === 'REQUESTED' ? (
          <ModalProposal
            modelId={model.id}
            modelLikes={model.likes}
            proposalType={model.proposalType}
            modelProposals={model.proposals}
            modelOrganizer={model.organizer}
            onUpdateModel={onUpdateModel}
          />
        ) : (
          <ModalParticipa model={model} onRefreshModel={redirectToDetail} />
        )}
      </Card.Body>
    </Card>
  )
}

export default CardProject
